import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createDeviceDetector } from 'next-vue-device-detector'
import VueSplide from '@splidejs/vue-splide'
import VueLazyload from 'vue-lazyload'
import VueGoogleMaps from '@fawmi/vue-google-maps'

import '@splidejs/vue-splide/css'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import "bootstrap-icons/font/bootstrap-icons.min.css"

const app = createApp(App);
export const device = createDeviceDetector();

app.mixin({
    methods: {
        slugTitle(title) {
            var slug = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            slug = slug.replace(/đ/gi, 'd');
            // Trim the last whitespace
            slug = slug.replace(/\s*$/g, '');
            // Change whitespace to "-"
            slug = slug.replace(/\s+/g, '-');

            return slug;
        },

        formatPhone(str) {
            let cleaned = ('' + str).replace(/\D/g, '');
            let match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
            if (match) {
                return match[1] + ' ' + match[2] + ' ' + match[3]
            };
            return null
        },

        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
    }
})

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCHq4B_LVlh8XQBO4oA06V5Y4mpnuIGENQ',
        language: 'vi',
    },
})

router.beforeEach((to, next) => {
    document.title = `${ process.env.VUE_APP_TITLE } - ${to.meta.title}`
})

require('@/config/axios');

app.use(router)
    .use(device)
    .use(VueSplide)
    .use(VueLazyload)
    .mount('#app')
