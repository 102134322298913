import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'layout',
        component: () => import('@/components/index.vue'),
        meta: {
            title: 'Trang Chủ',
        },
        children: [
            {
                path: 'chu-dau-tu',
                name: 'investor',
                component: () => import('@/views/investor.vue'),
                meta: {
                    title: 'Chủ đầu tư'
                },
            },
            {
                path: 'chu-dau-tu/:id/:slug',
                name: 'project',
                component: () => import('@/views/project.vue'),
                meta: {
                    title: 'Chủ đầu tư'
                },
            },
            {
                path: 'du-an/:investorId/:projectId/:slug',
                name: 'project-detail',
                component: () => import('@/views/project/index.vue'),
                meta: {
                    title: 'Dự án',
                },
                children: [
                    {
                        path: 'flat',
                        name: 'project-detail-flat',
                        component: () => import('@/views/project/flat.vue'),
                        meta: {
                            title: 'Mặt bằng'
                        },
                    },
                    {
                        path: 'maps',
                        name: 'project-detail-maps',
                        component: () => import('@/views/project/maps.vue'),
                        meta: {
                            title: 'Bản đồ'
                        },
                    },
                    {
                        path: 'info',
                        name: 'project-detail-info',
                        component: () => import('@/views/project/info.vue'),
                        meta: {
                            title: 'Thông tin dự án'
                        },
                    },
                ]
            },
            {
                path: 'info',
                name: 'info',
                component: () => import('@/views/info.vue'),
                meta: {
                    title: 'Thông tin'
                },
            },
            {
                path: 'news',
                name: 'news',
                component: () => import('@/views/news/index.vue'),
                meta: {
                    title: 'Tin tức'
                },
            },
            {
                path: 'news/:id/:slug',
                name: 'news-detail',
                component: () => import('@/views/news/detail.vue'),
                meta: {
                    title: 'Tin tức'
                },
            },
            {
                path: 'contact',
                name: 'contact',
                component: () => import('@/views/contact/index.vue'),
                meta: {
                    title: 'Liên hệ'
                },
            },
            {
                path: 'sa-ban-ao',
                name: 'physical-model',
                component: () => import('@/views/physicalModel.vue'),
                meta: {
                    title: 'Sa bàn ảo'
                },
            },
            {
                path: 'search',
                name: 'search',
                component: () => import('@/views/search.vue'),
                props: route => ({ query: route.query.keyword }),
                meta: {
                    title: 'Kết quả tìm kiếm'
                },
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/404.vue'),
        meta: {
            title: 'Not Found',
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes
})

export default router
