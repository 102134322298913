<template>
    <router-view/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
* {
    font-family: 'Montserrat', sans-serif !important;
}
input, button, textarea, select {
    &:focus {
        box-shadow: none !important;
    }
}
.px-100 {
    padding-left: 100px;
    padding-right: 100px;
}
.bg-base {
    background: linear-gradient(180deg, rgba(0,105,216,1) 0%, rgba(1,14,37,1) 100%) !important;
}

.slide-origin, .slide-mobile {
    .splide__track {
        box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12),0 5px 5px -3px rgba(0,0,0,0.2);
        border: 5px solid #fff;
        border-radius: 4px;
        background-image: url('~@/assets/logo.png');
        background-repeat: no-repeat;
        background-position: center;
    }
}
.scrollbar {
    scrollbar-color: #fff transparent;
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #fff;
        border-radius: 10px;
    }
}
</style>
